.iframeContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  iframe {
    width: 100%;
    height: 100%;
    min-height: 500px;
  }
}