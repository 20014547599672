

.member {
  display: flex;
  width: 320px;
  height: 320px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  img {
    width: 240px;
    height: 240px;
    flex-shrink: 0;
    border-radius: 120px;
    box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.05);
    object-fit: contain;
  }
  h2 {
    color: #000;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }
  p {
    color: #888;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }
}
