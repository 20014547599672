.box {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Sizing-M, 16px);
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 24px;
  min-width: 250px;
  max-width: 320px;
  justify-content: space-between;
  .title {
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 60px; /* 150% */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .description {
    color: #222;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 150% */
  }
  button {
    display: flex;
    padding: 8px var(--Sizing-M, 16px);
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-radius: 8px;
    border: none;
    color: #222;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px; /* 144.444% */
  }
}
.silver {
  border: 1px solid #888;
  background: var(--white-black-white, #FFF);
  box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
  .title {
    background: var(--GR-SIlver, linear-gradient(90deg, rgba(204, 204, 204, 0.80) 0%, #888 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  button {
    background: var(--GR-SIlver, linear-gradient(90deg, rgba(204, 204, 204, 0.80) 0%, #888 100%));
    box-shadow: 0px 4px 16px 0px rgba(34, 34, 34, 0.10);
  }
}
.gold {
  border: 1px solid var(--GR-Gold, #FFCF0B);
  background: var(--white-black-white, #FFF);
  box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
  .title {
    background: var(--GR-Gold, linear-gradient(90deg, #FFCF0B 0%, #C1A510 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  button {
    background: var(--GR-Gold, linear-gradient(90deg, #FFCF0B 0%, #C1A510 100%));
    box-shadow: 0px 4px 16px 0px rgba(34, 34, 34, 0.10);
  }
}
.platinum {
  border: 1px solid var(--GR, #8F00FF);
  background: var(--white-black-white, #FFF);
  box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
  .title {
    background-clip: text;
    background: var(--GR, linear-gradient(90deg, #8F00FF 0%, #1400FF 100%));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  button {
    background: var(--GR, linear-gradient(90deg, #8F00FF 0%, #1400FF 100%));
    box-shadow: 0px 4px 16px 0px rgba(34, 34, 34, 0.10);
    color: white;
  }
}
