

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  background: #FFCD00;
  color: #222;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px; /* 144.444% */
  padding: 8px 16px;
  @media (max-width: 350px) {
    padding: 8px 10px;
  }
  cursor: pointer;
  outline:0;
  border: none;
}
.primary {
  background: #FFCD00;
  box-shadow: 0px 4px 26px 0px rgba(78, 26, 165, 0.25);
}
.secondary {
  background: #C4A6FF;
  background: rgba(196, 166, 255, 1);
}
.info {
  color: rgba(255, 255, 255, 1);
  background: rgba(103, 10, 193, 1);
}
.button:disabled {
  background: #D3D3D3;
  color: #A9A9A9;
  cursor: not-allowed;
}