

.serviceHeaderImage {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  object-fit: cover;
  @media (min-width: 768px) {
    display: block;
  }
}
.serviceHeaderMobileImage {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  object-fit: cover;
  @media (min-width: 768px) {
    display: none;
  }
}
.subheading {
  color: #670AC1;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
}
.fullImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title {
  color: #000;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 150% */
  @media (max-width: 768px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
  }
}