
.container {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(10px);
}
.nav {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #670AC1 0%, #4E0496 100%);
  box-shadow: -8px -8px 24px 0px rgba(255, 255, 255, 0.25), 8px 8px 24px 0px rgba(111, 0, 121, 0.25);
  border-radius: 24px;
  padding: 0 16px;
  padding-bottom: 0;
  margin: 32px;
  @media (max-width: 1300px) {
    margin: 32px 16px;
    margin-bottom: 0;
  }
  @media (max-width: 400px) {
    margin: 32px 8px;
    margin-bottom: 0;
  }
  @media (max-width: 350px) {
    margin: 16px 2px;
    margin-bottom: 0;
  }
  margin-bottom: 0;
  min-height: 80px;
  > a {
    display: flex;
  }
  .logo {
    height: 40px;
    margin-right: 48px;
    @media (max-width: 460px) {
      height: 30px;
    }
    @media (max-width: 1150px) {
      margin-right: 0;
    }
    width: auto;
    flex-shrink: 0;
  }
  .list {
    @media (max-width: 1150px) {
      display: none;
    }
    flex: 1;
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    li {
      list-style-type: none;
      a {
        padding: 8px;
        text-decoration: none;
        color: rgba(255, 255, 255, 0.90);
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 42px;
      }
      &:hover, &.active {
        border-radius: 26px;
        background: rgba(255, 255, 255, 0.50);
        box-shadow: -4px -4px 16px 0px rgba(255, 255, 255, 0.25), 4px 4px 16px 0px rgba(0, 0, 0, 0.25);
      }
    }
  }
  .buttons {
    display: flex;
    gap: 8px;
  }
  .active {
    border-radius: 26px;
    background: rgba(255, 255, 255, 0.50);
    box-shadow: -4px -4px 16px 0px rgba(255, 255, 255, 0.25), 4px 4px 16px 0px rgba(0, 0, 0, 0.25);
  }
  .mobileNav {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .menu {
    width: 24px;
    height: 24px;
    cursor: pointer;
    @media (min-width: 1150px) {
      display: none;
    }
  }
}
.mobileList {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 1;
  right: 20px;
  top: 90px;
  background: linear-gradient(90deg, #670AC1 0%, #4E0496 100%);
  box-shadow: 8px 8px 24px 0px rgba(111, 0, 121, 0.25);
  box-shadow: -8px -8px 24px 0px rgba(255, 255, 255, 0.25);
  padding: 16px 0px 0px 0px;
  gap: 8px;
  border-radius: 24px;
  padding-bottom: 20px;
  li {
    list-style-type: none;
    width: max-content;
    margin-left: 20px;
    a {
      padding: 8px 16px;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.90);
      text-align: center;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 42px;
    }
    &:hover, &.active {
      border-radius: 26px;
      background: rgba(255, 255, 255, 0.50);
      box-shadow: -4px -4px 16px 0px rgba(255, 255, 255, 0.25), 4px 4px 16px 0px rgba(0, 0, 0, 0.25);
    }
  }
}