.title {
  text-align: center;
  font-size: 40px;
  font-style: normal;
  font-weight: 900;
  line-height: 60px; /* 150% */
  background: var(--GR, linear-gradient(90deg, #8F00FF 0%, #1400FF 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}