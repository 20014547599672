.container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  h2 {
    color: #222;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px; /* 133.333% */
  }
  p {
    color: #888;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px; /* 150% */
  }
}