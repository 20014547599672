

.container {
  display: flex;
  padding: 120px 160px;
  @media (max-width: 1430px) {
    padding: 80px 40px;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  position: relative;
  background: var(--GR, linear-gradient(90deg, #8F00FF 0%, #1400FF 100%));
  .image {
    width: 390px;
    height: 374px;
    position: absolute;
    top: 69px;
    left: 0;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .title {
    color: var(--white-black-white, #FFF);
    text-align: center;
  }
}