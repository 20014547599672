.quote {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  gap: var(--Sizing-M, 16px);
  align-self: stretch;
  border-radius: 24px;
  background: var(--white-black-white, #FFF);
  box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
  .author {
    display: flex;
    flex-direction: column;
  }
  .text {
    color: #222;
    text-align: center;
    font-size: 20px;
    font-style: italic;
    font-weight: 300;
    line-height: 30px; /* 150% */
  }
  .name {
    color: #222;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 900;
    line-height: 30px; /* 150% */
  }
  .position {
    color: #888;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 900;
    line-height: 20px; /* 142.857% */
  }
}