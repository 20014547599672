.container {
  padding: 80px 120px;
  margin-top: 150px;
  @media (max-width: 1024px) {
    margin-top: 100px;
  }
  @media (max-width: 768px) {
    padding: 40px 60px;
  }
  @media (max-width: 480px) {
    padding: 24px 16px;
  }
  display: flex;
  width: 100%;
  gap: 80px;
  flex-direction: column;
  .serviceHeaderImage {
    position: absolute;
    right: 0;
    z-index: 3;
    bottom: 0;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  .pricingHeader {
    background: var(--GR, linear-gradient(90deg, #8F00FF 0%, #1400FF 100%));
    overflow: visible;
    min-height: 0;
    h1 {
      color: var(--white-black-white, #FFF);
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px; /* 150% */
      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 32px; /* 133.333% */
      }
    }
    > p {
      color: var(--white-black-white, #FFF);
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 36px; /* 150% */
      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px; /* 150% */
      }
    }
  }
  .blackContainer {
    background: #222;
  }
  .fullContainer {
    h1 {
      max-width: 100%;
    }
    p {
      max-width: 100%;
    }
  }
  .pricingSection {
    display: flex;
    padding: 24px;
    @media (max-width: 500px) {
      padding: 12px;
    }
    flex-direction: column;
    align-items: center;
    gap: var(--Sizing-M, 16px);
    flex: 1 0 0;
    border-radius: 24px;
    background: var(--white-black-white, #FFF);
    box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
    .h2 {
      color: #222;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 900;
      line-height: 48px; /* 150% */
    }
  }
}