

.container {
  display: flex;
  padding: 120px 130px;
  @media (max-width: 1430px) {
    padding: 80px 10px;
  }
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  align-self: stretch;
  .services {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: center;
    justify-content: flex-start;
    max-width: 100%;
    overflow-x: auto;
    padding: 20px 30px;
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .title {
    color: #670AC1;
    text-align: center;
  }
  .button {
    display: flex;
    padding: var(--Sizing-M, 16px) 24px;
    justify-content: center;
    align-items: center;
    gap: var(--Sizing-M, 16px);
  }
}