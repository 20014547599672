.container {
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  h2 {
    color: #222;
    font-size: 24px;
    font-style: normal;
    font-weight: 900;
    line-height: 32px; /* 133.333% */
  }
  .services {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
  }
  .service {
    color: #444;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    display: flex;
    height: 72px;
    padding: 24px;
    align-items: flex-start;
    gap: 16px;
    border-radius: 24px;
    border: 1px solid #CCC;
    background: #FFF;
    box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
    cursor: pointer;
  }
  .active {
    border: 2px solid #670AC1;
  }
}