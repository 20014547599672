

.serviceHeaderImage {
  @media (min-width: 1440px) {
    height: 350px;
    width: auto;
    transform: rotate(-30deg);
    flex-shrink: 0;
    position: absolute;
    right: -80px;
    top: 80px;
  }
  z-index: 1;
}
