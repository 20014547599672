.wrapper {
  @media (max-width: 480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.container {
  margin-top: 150px;
  padding: 80px 160px;
  @media (max-width: 1440px) {
    padding: 40px 80px;
  }
  @media (max-width: 480px) {
    padding: 40px 16px;
  }
  display: flex;
  width: 100%;
  gap: 24px;
  flex-direction: column;
  border-radius: 24px;
  background:  url("../images/business-setup.png") lightgray 50% / cover no-repeat;

  .content {
    max-width: 600px;
    display: flex;
    gap: 24px;
    flex-direction: column;
    h1 {
      color: #FFF;
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 72px; /* 150% */
      @media (max-width: 768px) {
        font-size: 22px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 150% */
      }
    }
    h2 {
      color: #FFF;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 32px; /* 133.333% */
      @media (max-width: 768px) {
        font-size: 18px;
        font-style: normal;
        line-height: 20px; /* 142.857% */
      }
    }
    p {
      color: #FFF;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: 26px; /* 144.444% */
      @media (max-width: 768px) {
        font-size: 14px;
        font-style: normal;
        line-height: 20px; /* 142.857% */
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      gap: 16px;
      margin-top: 90px;
    }
  }
}