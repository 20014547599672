

.alert {
  display: flex;
  padding: 24px;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  border-radius: var(--Sizing-M, 16px);
  background: var(--GR, linear-gradient(90deg, #8F00FF 0%, #1400FF 100%));
  justify-content: space-between;
  @media (max-width: 767px) {
    flex-direction: column;
    button {
      width: 100%;
      flex: 1;
      align-self: normal;
    }
  }
  .text {
    flex: 1;
    color: var(--white-black-white, #FFF);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 150% */
  }
}