

.serviceHeaderImage {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  object-fit: cover;
  @media (min-width: 768px) {
    display: block;
  }
}
.serviceHeaderMobileImage {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 1;
  object-fit: cover;
  @media (min-width: 768px) {
    display: none;
  }
}
.subheading {
  color: #670AC1;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
}
.fullImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title {
  color: #000;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 150% */
  @media (max-width: 768px) {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
  }
}
.flagsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 16px;
  }
  .flagsImageContainer {
    display: flex;
    flex: 1;
    min-width: 300px;
    border-radius: 24px;
    background: var(--white-black-white, #FFF);
    box-shadow: -8px -8px 24px 0px rgba(223, 211, 248, 0.15), 8px 8px 24px 0px rgba(18, 3, 32, 0.05);
    align-self: normal;
  }
  .flagsImage {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    align-items: center;
    gap: var(--Sizing-M, 16px);
    flex: 1 0 0;
    object-fit: contain;
  }
  .services {
    flex: 1;
  }
}
.fullWidth {
  max-width: 100%;
}
.full {
  flex: 1 0 50%;
}